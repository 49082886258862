<template>
	<div :class="sideOverShow ? 'side-over--active' : ''">
		<div
			v-if="sideOverShow"
			class="
				fixed
				h-screen
				w-screen
				top-0
				left-0
				bg-black
				opacity-20
				z-30
			"
			@click="close"
		></div>
		<CreateNotify :formDefault="form" :open="sideOverShow" @close="close" />
	</div>
	<DaModalFrame v-model:open="modalIsOpen">
		<DeleteConfirm @close="closeModal" @submit="deleteForm" />
	</DaModalFrame>
	<div>
		<div
			v-if="formList && formList.length > 0"
			class="__report-lesson__container"
		>
			<div class="mb-10">
				<div class="mb-3 text-neutral-600 font-bold text-lg">
					Năm 2024
				</div>
				<div class="shadow-sm sm:rounded-2xl">
					<div class="__record__table">
						<div class="sm:rounded-t-2xl w-fit min-w-full">
							<table class="min-w-full divide-y text-neutral-200">
								<thead class="bg-neutral-100 h-16">
									<tr>
										<th
											class="
												px-3
												py-3
												text-center text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												w-16
												whitespace-nowrap
											"
											scope="col"
										>
											STT
										</th>
										<th
											class="
												px-3
												py-3
												text-left text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												whitespace-nowrap
											"
											scope="col"
										>
											Tên biểu mẫu
										</th>
										<th
											class="
												px-3
												py-3
												text-left text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												whitespace-nowrap
											"
											scope="col"
										>
											Số thông báo đã gửi
										</th>
										<th
											class="
												px-3
												py-3
												text-left text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												whitespace-nowrap
											"
											scope="col"
										>
											Người tạo
										</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="(item, index) in formList"
										:key="item.id"
										:class="
											index % 2 === 0
												? 'bg-white'
												: 'bg-neutral-50'
										"
										class="
											text-neutral-700
											cursor-pointer
											hover:bg-orange-50
										"
									>
										<td>
											<div
												class="
													px-3
													py-4
													text-center text-neutral-700
												"
											>
												{{ index + 1 }}
											</div>
										</td>
										<td
											class="
												px-3
												py-4
												text-neutral-700
												cursor-pointer
											"
											style="max-width: 200px"
										>
											<div>
												{{ item?.name }}
											</div>
										</td>
										<td
											class="
												px-3
												py-4
												whitespace-nowrap
												text-neutral-700
											"
										>
											<div>
												{{ item?.used_count || '0' }}
											</div>
										</td>
										<td
											class="
												px-3
												py-4
												whitespace-nowrap
												text-neutral-700
											"
										>
											<div
												v-if="item.created_by_staff"
												class="flex items-center mb-4"
											>
												<div>
													<img
														v-if="
															item
																.created_by_staff
																?.profile_photo
																?.w200
														"
														:src="
															item
																.created_by_staff
																?.profile_photo
																?.w200
														"
														alt=""
														class="
															shadow-sm
															inline-block
															h-9
															w-9
															rounded-full
														"
													/>
													<span
														v-else
														:style="{
															background:
																item
																	.created_by_staff
																	?.profile_photo
																	?.default
																	?.color,
														}"
														class="
															inline-flex
															items-center
															justify-center
															h-9
															w-9
															rounded-full
														"
													>
														<span
															class="
																font-medium
																leading-none
																text-white
																text-xs
															"
															>{{
																item
																	.created_by_staff
																	?.profile_photo
																	?.default
																	?.initial
															}}</span
														>
													</span>
												</div>
												<div
													class="
														ml-4
														text-blue-600
														font-semibold
														truncate
													"
												>
													{{
														item.created_by_staff
															.name
													}}
												</div>
											</div>
										</td>
										<td>
											<div
												class="
													flex
													items-center
													justify-end
													gap-2
													px-4
												"
											>
												<button
													class="
														button-p
														btn--ghost
														w-fit
														px-4
													"
													type="button"
													@click="
														openCreateNotify(item)
													"
												>
													Tạo thông báo
												</button>
												<Menu
													as="div"
													class="ml-3 relative"
												>
													<MenuButton
														class="
															rounded-full
															flex
															text-sm
															focus:outline-none
															focus:ring-2
															focus:ring-offset-2
															focus:ring-indigo-500
														"
													>
														<span class="sr-only"
															>Open user
															menu</span
														>
														<svg
															fill="none"
															height="20"
															viewBox="0 0 20 20"
															width="20"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																clip-rule="evenodd"
																d="M11.875 16.25C11.875 17.2855 11.0355 18.125 10 18.125C8.96447 18.125 8.125 17.2855 8.125 16.25C8.125 15.2145 8.96447 14.375 10 14.375C11.0355 14.375 11.875 15.2145 11.875 16.25ZM11.875 10C11.875 11.0355 11.0355 11.875 10 11.875C8.96447 11.875 8.125 11.0355 8.125 10C8.125 8.96447 8.96447 8.125 10 8.125C11.0355 8.125 11.875 8.96447 11.875 10ZM11.875 3.75C11.875 4.78553 11.0355 5.625 10 5.625C8.96447 5.625 8.125 4.78553 8.125 3.75C8.125 2.71447 8.96447 1.875 10 1.875C11.0355 1.875 11.875 2.71447 11.875 3.75Z"
																fill="#94A3B8"
																fill-rule="evenodd"
															/>
														</svg>
													</MenuButton>
													<transition
														enter-active-class="transition ease-out duration-200"
														enter-from-class="transform opacity-0 scale-95"
														enter-to-class="transform opacity-100 scale-100"
														leave-active-class="transition ease-in duration-75"
														leave-from-class="transform opacity-100 scale-100"
														leave-to-class="transform opacity-0 scale-95"
													>
														<MenuItems
															class="
																origin-top-right
																absolute
																right-0
																mt-2
																w-60
																rounded-md
																py-1
																bg-white
																focus:outline-none
																z-10
																shadow-sm
																border
																border-yellow-100
															"
														>
															<MenuItem
																v-slot="{
																	active,
																}"
																@click="
																	edit(item)
																"
															>
																<div
																	:class="
																		active
																			? ''
																			: ''
																	"
																	class="
																		p-2
																		px-4
																		bg-white
																		z-10
																		text-gray-600
																		hover:bg-neutral-50
																		flex
																		items-center
																		gap-4
																		cursor-pointer
																	"
																>
																	<svg
																		fill="none"
																		height="16"
																		viewBox="0 0 16 16"
																		width="16"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			clip-rule="evenodd"
																			d="M12.47 6.65078L13.71 5.41075C13.8459 5.27489 13.9138 5.20696 13.9664 5.14499C14.6006 4.39832 14.6006 3.30214 13.9664 2.55546C13.9138 2.4935 13.8459 2.42557 13.71 2.28971C13.5741 2.15385 13.5062 2.08592 13.4442 2.03329C12.6976 1.39906 11.6014 1.39906 10.8547 2.03329C10.7928 2.08591 10.7249 2.15382 10.5891 2.28961L10.589 2.28969L10.589 2.28971L9.33075 3.54792C10.0744 4.84212 11.1581 5.91739 12.47 6.65078ZM7.87608 5.00258L2.86248 10.0162C2.43742 10.4412 2.22489 10.6538 2.08516 10.9149C1.94542 11.176 1.88648 11.4707 1.76859 12.0601L1.37293 14.0384C1.30641 14.371 1.27315 14.5373 1.36776 14.6319C1.46237 14.7266 1.62867 14.6933 1.96128 14.6268L3.93956 14.2311L3.93958 14.2311L3.9396 14.2311C4.52902 14.1132 4.82374 14.0543 5.08483 13.9145C5.34592 13.7748 5.55845 13.5623 5.98349 13.1373L5.98352 13.1372L5.98352 13.1372L11.0115 8.10922C9.75086 7.31101 8.68177 6.24913 7.87608 5.00258Z"
																			fill="#94A3B8"
																			fill-rule="evenodd"
																		/>
																	</svg>
																	<span
																		class="
																			mt-0.5
																		"
																		>Chỉnh
																		sửa biểu
																		mẫu</span
																	>
																</div>
															</MenuItem>
															<MenuItem
																v-slot="{
																	active,
																}"
																@click="
																	preview(
																		item
																	)
																"
															>
																<div
																	:class="
																		active
																			? ''
																			: ''
																	"
																	class="
																		p-2
																		px-4
																		bg-white
																		z-10
																		text-gray-600
																		hover:bg-neutral-50
																		flex
																		items-center
																		gap-4
																		cursor-pointer
																	"
																>
																	<svg
																		fill="none"
																		height="16"
																		viewBox="0 0 16 16"
																		width="16"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M10.2915 7.99967C10.2915 9.26533 9.26549 10.2913 7.99984 10.2913C6.73418 10.2913 5.70817 9.26533 5.70817 7.99967C5.70817 6.73402 6.73418 5.70801 7.99984 5.70801C9.26549 5.70801 10.2915 6.73402 10.2915 7.99967Z"
																			fill="#94A3B8"
																		/>
																		<path
																			clip-rule="evenodd"
																			d="M0.666504 7.99967C0.666504 7.99967 3.4165 2.95801 7.99984 2.95801C12.5832 2.95801 15.3332 7.99967 15.3332 7.99967C15.3332 7.99967 12.5832 13.0413 7.99984 13.0413C3.4165 13.0413 0.666504 7.99967 0.666504 7.99967ZM7.99984 11.208C9.77175 11.208 11.2082 9.77159 11.2082 7.99967C11.2082 6.22776 9.77175 4.79134 7.99984 4.79134C6.22792 4.79134 4.7915 6.22776 4.7915 7.99967C4.7915 9.77159 6.22792 11.208 7.99984 11.208Z"
																			fill="#94A3B8"
																			fill-rule="evenodd"
																		/>
																	</svg>

																	<span
																		class="
																			mt-0.5
																		"
																		>Xem
																		trước
																		biểu
																		mẫu</span
																	>
																</div>
															</MenuItem>
															<MenuItem
																v-slot="{
																	active,
																}"
																@click="
																	openDeleteModal(
																		item
																	)
																"
															>
																<div
																	:class="
																		active
																			? ''
																			: ''
																	"
																	class="
																		p-2
																		px-4
																		bg-white
																		z-10
																		text-gray-600
																		hover:bg-neutral-50
																		flex
																		items-center
																		gap-4
																		cursor-pointer
																	"
																>
																	<svg
																		fill="none"
																		height="16"
																		viewBox="0 0 16 16"
																		width="16"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			clip-rule="evenodd"
																			d="M2.5 1C1.94772 1 1.5 1.44772 1.5 2V3C1.5 3.55228 1.94772 4 2.5 4H3V13C3 14.1046 3.89543 15 5 15H11C12.1046 15 13 14.1046 13 13V4H13.5C14.0523 4 14.5 3.55228 14.5 3V2C14.5 1.44772 14.0523 1 13.5 1H10C10 0.447715 9.55229 0 9 0H7C6.44772 0 6 0.447715 6 1H2.5ZM5.5 5C5.77614 5 6 5.22386 6 5.5V12.5C6 12.7761 5.77614 13 5.5 13C5.22386 13 5 12.7761 5 12.5L5 5.5C5 5.22386 5.22386 5 5.5 5ZM8 5C8.27614 5 8.5 5.22386 8.5 5.5V12.5C8.5 12.7761 8.27614 13 8 13C7.72386 13 7.5 12.7761 7.5 12.5V5.5C7.5 5.22386 7.72386 5 8 5ZM11 5.5C11 5.22386 10.7761 5 10.5 5C10.2239 5 10 5.22386 10 5.5V12.5C10 12.7761 10.2239 13 10.5 13C10.7761 13 11 12.7761 11 12.5V5.5Z"
																			fill="#94A3B8"
																			fill-rule="evenodd"
																		/>
																	</svg>

																	<span
																		class="
																			mt-0.5
																		"
																		>Xoá
																		biểu
																		mẫu</span
																	>
																</div>
															</MenuItem>
														</MenuItems>
													</transition>
												</Menu>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-else
			class="w-full h-full flex items-center justify-center flex-col"
		>
			<img
				alt=""
				height="250"
				src="@/assets/images/illustrations/not_found.png"
				width="250"
			/>
			<div
				class="text-neutral-500 text-lg text-center"
				style="max-width: 700px"
			>
				Trung tâm chưa có biểu mẫu
			</div>
			<button
				class="button-p btn--ghost w-fit px-4 mt-4"
				type="button"
				@click="goToCreate"
			>
				Tạo biểu mẫu mới
			</button>
		</div>
	</div>
</template>

<script>
import { $api } from '@/services';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import DeleteConfirm from '@/views/forms/DeleteConfirm.vue';
import { _ROUTER_NAME } from '@/enums';
import CreateNotify from '@/views/forms/CreateNotify.vue';

export default {
	components: {
		CreateNotify,
		DeleteConfirm,
		DaModalFrame,
		Menu,
		MenuItems,
		MenuItem,
		MenuButton,
	},
	data() {
		return {
			formList: [],
			isLoading: false,
			deletedData: null,
			modalIsOpen: false,
			sideOverShow: false,
			form: null,
			forms: [
				{
					id: 1,
					title: 'Khoản thu',
					cards: [
						{
							id: 1,
							title: 'Học can thiệp cá nhân',
							disabled: false,
							fields: [
								{
									id: 1,
									type: 'number',
									placeholder: 'Nhập chi phí',
									label: 'Chi phí thu',
									value: '',
									disabled: true,
								},
								{
									id: 2,
									type: 'text',
									placeholder:
										'Hệ thống đếm số giờ học của học sinh',
									label: 'Số lượng',
									value: '',
									disabled: true,
								},
								{
									id: 3,
									name: 'personal_fee_3',
									type: 'text',
									placeholder:
										'Hệ thống tính số tiền phải đóng',
									label: 'Thành tiền',
									value: '',
									disabled: true,
								},
							],
						},
						{
							id: 2,
							title: 'Học can thiệp lớp nhóm',
							disabled: false,
							fields: [
								{
									id: 1,
									type: 'number',
									placeholder: 'Nhập chi phí',
									label: 'Chi phí thu',
									value: '',
									disabled: true,
								},
								{
									id: 2,
									type: 'text',
									placeholder:
										'Hệ thống đếm số giờ học của học sinh',
									label: 'Số lượng',
									value: '',
									disabled: true,
								},
								{
									id: 3,
									type: 'text',
									placeholder:
										'Hệ thống tính số tiền phải đóng',
									label: 'Thành tiền',
									value: '',
									disabled: true,
								},
							],
						},
						{
							id: 3,
							title: 'Học bán trú',
							disabled: false,
							fields: [
								{
									id: 1,
									type: 'number',
									placeholder: 'Nhập chi phí',
									label: 'Chi phí thu',
									value: '',
									disabled: true,
								},
								{
									id: 2,
									type: 'text',
									placeholder:
										'Hệ thống đếm số giờ học của học sinh',
									label: 'Số lượng',
									value: '',
									disabled: true,
								},
								{
									id: 3,
									type: 'text',
									placeholder:
										'Hệ thống tính số tiền phải đóng',
									label: 'Thành tiền',
									value: '',
									disabled: true,
								},
							],
						},
						{
							id: 4,
							title: 'Phí sử dụng sổ nhật ký điện tử',
							disabled: false,
							fields: [
								{
									id: 1,
									type: 'number',
									placeholder: 'Nhập chi phí',
									label: 'Chi phí thu',
									value: '',
									disabled: true,
								},
								{
									id: 2,
									type: 'text',
									placeholder:
										'Hệ thống đếm số giờ học của học sinh',
									label: 'Số lượng',
									value: '',
									disabled: true,
								},
								{
									id: 3,
									type: 'text',
									placeholder:
										'Hệ thống tính số tiền phải đóng',
									label: 'Thành tiền',
									value: '',
									disabled: true,
								},
							],
						},
					],
				},
				{
					id: 2,
					title: 'Hoàn phí',
					cards: [
						{
							id: 1,
							title: 'Học can thiệp cá nhân',
							disabled: false,
							fields: [
								{
									id: 1,
									type: 'number',
									placeholder: 'Nhập chi phí',
									label: 'Chi phí hoàn',
									value: '',
									disabled: true,
								},
								{
									id: 2,
									type: 'text',
									placeholder:
										'Hệ thống đếm số giờ nghỉ của học sinh',
									label: 'Số lượng',
									value: '',
									disabled: true,
								},
								{
									id: 3,
									name: 'personal_fee_3',
									type: 'text',
									placeholder:
										'Hệ thống tính số tiền được hoàn trả',
									label: 'Thành tiền',
									value: '',
									disabled: true,
								},
							],
						},
						{
							id: 2,
							title: 'Học can thiệp lớp nhóm',
							disabled: false,
							fields: [
								{
									id: 1,
									type: 'number',
									placeholder: 'Nhập chi phí',
									label: 'Chi phí hoàn',
									value: '',
									disabled: true,
								},
								{
									id: 2,
									type: 'text',
									placeholder:
										'Hệ thống đếm số giờ nghỉ của học sinh',
									label: 'Số lượng',
									value: '',
									disabled: true,
								},
								{
									id: 3,
									type: 'text',
									placeholder:
										'Hệ thống tính số tiền được hoàn trả',
									label: 'Thành tiền',
									value: '',
									disabled: true,
								},
							],
						},
						{
							id: 3,
							title: 'Học bán trú',
							disabled: false,
							fields: [
								{
									id: 1,
									type: 'number',
									placeholder: 'Nhập chi phí',
									label: 'Chi phí hoàn',
									value: '',
									disabled: true,
								},
								{
									id: 2,
									type: 'text',
									placeholder:
										'Hệ thống đếm số giờ nghỉ của học sinh',
									label: 'Số lượng',
									value: '',
									disabled: true,
								},
								{
									id: 3,
									type: 'text',
									placeholder:
										'Hệ thống tính số tiền được hoàn trả',
									label: 'Thành tiền',
									value: '',
									disabled: true,
								},
							],
						},
					],
				},
			],
		};
	},

	methods: {
		async getFormsByMonth() {
			try {
				this.isLoading = true;
				const { data } = await $api.org.getForms();
				this.formList = data?.data || [];
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.isLoading = false;
			}
		},

		openDeleteModal(data) {
			this.deletedData = data;
			this.modalIsOpen = true;
		},

		closeModal() {
			this.deletedData = null;
			this.modalIsOpen = false;
		},

		async deleteForm() {
			try {
				this.isLoading = true;
				await $api.org.deleteForm(this.deletedData.id);
				await this.getFormsByMonth();
				this.$store.dispatch('setAlertMessage', {
					message: 'Xoá biểu mẫu thành công',
					type: 'success',
				});
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.isLoading = false;
				this.closeModal();
			}
		},

		goToCreate() {
			window.sessionStorage.removeItem('formPreview');
			this.$router.push({ name: _ROUTER_NAME.TUITION_FORMS_CREATE });
		},

		edit(item) {
			const data = window.sessionStorage.getItem('formPreview');
			window.sessionStorage.setItem(
				'formPreview',
				JSON.stringify({
					...(data
						? JSON.parse(data)
						: {
								forms: this.forms,
						  }),
					title: item.title,
					name: item.name,
				})
			);

			this.$router.push({
				name: _ROUTER_NAME.TUITION_FORMS_CREATE,
				query: { id: item.id },
			});
		},

		preview(item) {
			const data = window.sessionStorage.getItem('formPreview');
			window.sessionStorage.setItem(
				'formPreview',
				JSON.stringify({
					...(data
						? JSON.parse(data)
						: {
								forms: this.forms,
						  }),
					title: item.title,
					name: item.name,
				})
			);

			this.$router.push({
				name: _ROUTER_NAME.TUITION_FORMS_PREVIEW,
				query: { id: item.id },
			});
		},

		openCreateNotify(form) {
			this.form = form;
			this.sideOverShow = true;
		},

		close() {
			this.sideOverShow = false;
		},
	},

	mounted() {
		this.getFormsByMonth();
	},
};
</script>
