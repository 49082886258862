<template>
	<ViewPhoto
		v-if="imageShow && images.length"
		:key="photosIndex"
		:images="images"
		:index="photosIndex"
		@close="imageShow = false"
	/>
	<DaModalFrame v-model:open="modalIsOpen">
		<ConfirmSendFeeNotice
			v-if="modalType === type.CREATE && notificationConfirm"
			:in-progress="inProgress"
			:notification="{
				...notificationConfirm,
				notice: {
					...notificationConfirm.notice,
					files,
					images,
				},
			}"
			is-network
			@close="cancel"
			@openViewPhoto="openMediaPopup(0)"
			@submit="sendNotification"
		/>
		<ConfirmResend
			v-if="modalType === type.UPDATE"
			:times="lastSendAt"
			@close="cancel"
			@submit="reSendNotification"
		/>
		<DeleteConfirm
			v-if="modalType === type.DELETE"
			@close="cancel"
			@submit="deleteNotification"
		/>
	</DaModalFrame>
	<div class="pb-30 px-10 md-down:px-4">
		<div
			class="
				__report-lesson__header
				pt-10
				pb-10
				flex flex-nowrap
				justify-between
				items-center
			"
		>
			<div
				class="
					__record__title
					text-neutral-500
					font-semibold
					whitespace-nowrap
					flex flex-nowrap
					items-center
					text-4xl
					cursor-pointer
				"
				@click="$router.push('/fee-notices')"
			>
				<svg
					class="mr-2"
					fill="none"
					height="28"
					viewBox="0 0 28 28"
					width="28"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M17.5 7L10.5 14L17.5 21"
						stroke="#94A3B8"
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
					/>
				</svg>
				Xem trước
			</div>
			<div
				v-if="notification.notice.state === 'draft'"
				class="flex items-center justify-end gap-6"
			>
				<div
					class="
						flex
						items-center
						text-yellow-600
						hover:text-yellow-700
						font-semibold
						cursor-pointer
						text-sm
					"
					@click="deleteOpen"
				>
					<svg
						class="mr-2"
						fill="none"
						height="16"
						viewBox="0 0 16 16"
						width="16"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							clip-rule="evenodd"
							d="M2.5 1C1.94772 1 1.5 1.44772 1.5 2V3C1.5 3.55228 1.94772 4 2.5 4H3V13C3 14.1046 3.89543 15 5 15H11C12.1046 15 13 14.1046 13 13V4H13.5C14.0523 4 14.5 3.55228 14.5 3V2C14.5 1.44772 14.0523 1 13.5 1H10C10 0.447715 9.55229 0 9 0H7C6.44772 0 6 0.447715 6 1H2.5ZM5.5 5C5.77614 5 6 5.22386 6 5.5V12.5C6 12.7761 5.77614 13 5.5 13C5.22386 13 5 12.7761 5 12.5L5 5.5C5 5.22386 5.22386 5 5.5 5ZM8 5C8.27614 5 8.5 5.22386 8.5 5.5V12.5C8.5 12.7761 8.27614 13 8 13C7.72386 13 7.5 12.7761 7.5 12.5V5.5C7.5 5.22386 7.72386 5 8 5ZM11 5.5C11 5.22386 10.7761 5 10.5 5C10.2239 5 10 5.22386 10 5.5V12.5C10 12.7761 10.2239 13 10.5 13C10.7761 13 11 12.7761 11 12.5V5.5Z"
							fill="currentColor"
							fill-rule="evenodd"
						/>
					</svg>
					<span class="mt-0.5">Xoá</span>
				</div>
				<div
					class="
						flex
						items-center
						text-yellow-600
						hover:text-yellow-700
						font-semibold
						cursor-pointer
						text-sm
					"
					@click="$router.push(`/fee-notices/${id}/edit`)"
				>
					<svg
						class="mr-2"
						fill="none"
						height="16"
						viewBox="0 0 16 16"
						width="16"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							clip-rule="evenodd"
							d="M12.4702 6.65078L13.7102 5.41075C13.8461 5.27489 13.914 5.20696 13.9667 5.14499C14.6009 4.39832 14.6009 3.30214 13.9667 2.55546C13.914 2.4935 13.8461 2.42557 13.7102 2.28971C13.5744 2.15385 13.5064 2.08592 13.4445 2.03329C12.6978 1.39906 11.6016 1.39906 10.855 2.03329C10.793 2.08591 10.7251 2.15382 10.5893 2.28961L10.5892 2.28969L10.5892 2.28971L9.33099 3.54792C10.0747 4.84212 11.1583 5.91739 12.4702 6.65078ZM7.87633 5.00258L2.86273 10.0162C2.43767 10.4412 2.22514 10.6538 2.0854 10.9149C1.94567 11.176 1.88672 11.4707 1.76883 12.0601L1.37318 14.0384C1.30666 14.371 1.27339 14.5373 1.368 14.6319C1.46261 14.7266 1.62892 14.6933 1.96153 14.6268L3.9398 14.2311L3.93982 14.2311L3.93984 14.2311C4.52927 14.1132 4.82399 14.0543 5.08508 13.9145C5.34617 13.7748 5.55869 13.5623 5.98373 13.1373L5.98376 13.1372L5.98377 13.1372L11.0118 8.10922C9.7511 7.31101 8.68202 6.24913 7.87633 5.00258Z"
							fill="currentColor"
							fill-rule="evenodd"
						/>
					</svg>
					<span class="mt-0.5">Chỉnh sửa</span>
				</div>
			</div>
		</div>
		<div class="bg-white rounded-xl border p-6">
			<div class="flex items-center gap-4">
				<img
					:src="currentOrg.profile_photo.w200"
					alt="Tuition template"
					class="h-16 rounded-lg"
				/>
				<div>
					<div class="font-semibold">{{ currentOrg.name }}</div>
					<div class="text-sm text-neutral-500">
						{{ currentOrg.address }}
					</div>
				</div>
			</div>
			<div class="flex items-center justify-center flex-col">
				<div class="font-semibold text-2xl">
					{{ studentForm.title }}
				</div>
				<div class="text-sm italic text-neutral-500">
					Ngày 11 tháng 09 năm 2024
				</div>
			</div>
			<div class="mt-8">
				<div class="text-sm text-neutral-700">
					Học sinh: {{ studentForm?.student?.name }}
				</div>
				<div class="text-sm text-neutral-700 mt-4">
					Lớp bán trú: {{ studentForm.day_care_class?.name }}
				</div>
				<div class="text-sm text-neutral-700 mt-4 flex gap-1">
					<div>Lớp nhóm:</div>
					<div>
						<div
							v-for="gClass in studentForm.group_classes"
							:key="gClass.id"
						>
							Lớp nhóm hè 2024
						</div>
					</div>
				</div>
			</div>
			<div class="mt-4">
				<div v-for="(form, i) in forms" :key="form.id">
					<div class="w-fit min-w-full">
						<table class="min-w-full text-neutral-200">
							<thead class="h-12">
								<tr>
									<th
										class="
											px-3
											py-3
											text-center text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											w-16
											whitespace-nowrap
											border
										"
										scope="col"
									>
										STT
									</th>
									<th
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
											border
										"
										scope="col"
									>
										Khoản thu
									</th>
									<th
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
											border
										"
										scope="col"
									>
										Chi phí (VNĐ)
									</th>
									<th
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
											border
										"
										scope="col"
									>
										Số lượng
									</th>
									<th
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
											border
										"
										scope="col"
									>
										Thành tiền (VNĐ)
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(item, index) in form.cards"
									:key="item.id"
									class="text-neutral-700"
								>
									<td class="border">
										<div
											class="
												px-3
												py-4
												text-center text-neutral-700
											"
										>
											{{ index + 1 }}
										</div>
									</td>
									<td
										class="
											px-3
											py-4
											text-neutral-700
											cursor-pointer
											border
										"
										style="max-width: 200px"
									>
										<div>
											{{ item?.title }}
										</div>
									</td>
									<td
										class="
											px-3
											py-4
											whitespace-nowrap
											text-neutral-700
											border
										"
									>
										<div>
											{{
												$filters.priceFormat(
													item.canDelete
														? item.fields[1].value
														: item.fields[0].value
												)
											}}
										</div>
									</td>
									<td
										class="
											px-3
											py-4
											whitespace-nowrap
											text-neutral-700
											border
										"
									>
										{{
											$filters.priceFormat(
												item.canDelete
													? item.fields[2].value
													: item.fields[1].value
											)
										}}
									</td>
									<td
										class="
											px-3
											py-4
											whitespace-nowrap
											text-neutral-700
											border
										"
									>
										{{
											$filters.priceFormat(
												item.canDelete
													? item.fields[3].value
													: item.fields[2].value
											)
										}}
									</td>
								</tr>
							</tbody>
						</table>
						<div
							class="
								capitalize
								h-12
								flex
								items-center
								justify-between
								bg-neutral-100
								border-l border-r
								px-4
								font-semibold
								text-neutral-500
							"
						>
							<div>Tổng {{ form.title }}</div>
							<div>
								{{
									$filters.priceFormat(
										studentForm?.data[
											i === 0 ? 'fee' : 'refund'
										]?.total
									)
								}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				class="
					capitalize
					h-12
					flex
					items-center
					justify-between
					border
					px-4
					font-semibold
					text-lg text-neutral-500
				"
			>
				<div>Tổng chi phí phụ huynh phải đóng</div>
				<div>{{ $filters.priceFormat(studentForm.data.total) }}</div>
			</div>
		</div>
		<div
			v-if="notification.notice.state === 'draft'"
			class="mt-10 flex items-center justify-center md:justify-end"
		>
			<button
				class="button-p text-center w-fit px-10"
				type="button"
				@click="confirmSendOpen"
			>
				Gửi thông báo
			</button>
		</div>
		<div
			v-else
			class="mt-10 flex items-center justify-center md:justify-end"
		>
			<button
				class="button-p text-center w-fit px-10"
				type="button"
				@click="confirmResendOpen"
			>
				Gửi thông báo
			</button>
		</div>
	</div>
</template>

<script>
import { $api } from '@/services';
import { ACTIONS } from '@/enums/Common';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame';
import ConfirmSendFeeNotice from '@/views/feeNoticesCreate/ConfirmSendFeeNotice';
import ConfirmResend from '@/views/feeNotoceDetail/features/ConfirmResend';
import DeleteConfirm from '@/views/notificationDetail/features/DeleteConfirm';
import { timeSince } from '@/helper/formatDate';
import ViewPhoto from '@/components/common/ViewPhoto';
import dayjs from 'dayjs';

export default {
	name: 'FeeNoticeDetail',
	components: {
		ViewPhoto,
		DeleteConfirm,
		ConfirmResend,
		ConfirmSendFeeNotice,
		DaModalFrame,
	},
	data() {
		return {
			modalIsOpen: false,
			modalType: false,
			notificationConfirm: null,
			notification: {
				inserted_at: null,
				month: '',
				notice: {},
				org_name: '',
				student: {},
			},
			files: [],
			images: [],
			file: null,
			parents: [],
			lastSendAt: '',
			inProgress: false,
			imageShow: false,
			photosIndex: 0,
			forms: [
				{
					id: 1,
					title: 'Khoản thu',
					cards: [
						{
							id: 1,
							title: 'Học can thiệp cá nhân',
							disabled: false,
							fields: [
								{
									id: 1,
									type: 'number',
									placeholder: 'Nhập chi phí',
									label: 'Chi phí thu',
									value: '',
									disabled: true,
								},
								{
									id: 2,
									type: 'text',
									placeholder:
										'Hệ thống đếm số giờ học của học sinh',
									label: 'Số lượng',
									value: '',
									disabled: true,
								},
								{
									id: 3,
									name: 'personal_fee_3',
									type: 'text',
									placeholder:
										'Hệ thống tính số tiền phải đóng',
									label: 'Thành tiền',
									value: '',
									disabled: true,
								},
							],
						},
						{
							id: 2,
							title: 'Học can thiệp lớp nhóm',
							disabled: false,
							fields: [
								{
									id: 1,
									type: 'number',
									placeholder: 'Nhập chi phí',
									label: 'Chi phí thu',
									value: '',
									disabled: true,
								},
								{
									id: 2,
									type: 'text',
									placeholder:
										'Hệ thống đếm số giờ học của học sinh',
									label: 'Số lượng',
									value: '',
									disabled: true,
								},
								{
									id: 3,
									type: 'text',
									placeholder:
										'Hệ thống tính số tiền phải đóng',
									label: 'Thành tiền',
									value: '',
									disabled: true,
								},
							],
						},
						{
							id: 3,
							title: 'Học bán trú',
							disabled: false,
							fields: [
								{
									id: 1,
									type: 'number',
									placeholder: 'Nhập chi phí',
									label: 'Chi phí thu',
									value: '',
									disabled: true,
								},
								{
									id: 2,
									type: 'text',
									placeholder:
										'Hệ thống đếm số giờ học của học sinh',
									label: 'Số lượng',
									value: '',
									disabled: true,
								},
								{
									id: 3,
									type: 'text',
									placeholder:
										'Hệ thống tính số tiền phải đóng',
									label: 'Thành tiền',
									value: '',
									disabled: true,
								},
							],
						},
						{
							id: 4,
							title: 'Phí sử dụng sổ nhật ký điện tử',
							disabled: false,
							fields: [
								{
									id: 1,
									type: 'number',
									placeholder: 'Nhập chi phí',
									label: 'Chi phí thu',
									value: '',
									disabled: true,
								},
								{
									id: 2,
									type: 'text',
									placeholder:
										'Hệ thống đếm số giờ học của học sinh',
									label: 'Số lượng',
									value: '',
									disabled: true,
								},
								{
									id: 3,
									type: 'text',
									placeholder:
										'Hệ thống tính số tiền phải đóng',
									label: 'Thành tiền',
									value: '',
									disabled: true,
								},
							],
						},
					],
				},
				{
					id: 2,
					title: 'Hoàn phí',
					cards: [
						{
							id: 1,
							title: 'Học can thiệp cá nhân',
							disabled: false,
							fields: [
								{
									id: 1,
									type: 'number',
									placeholder: 'Nhập chi phí',
									label: 'Chi phí hoàn',
									value: '',
									disabled: true,
								},
								{
									id: 2,
									type: 'text',
									placeholder:
										'Hệ thống đếm số giờ nghỉ của học sinh',
									label: 'Số lượng',
									value: '',
									disabled: true,
								},
								{
									id: 3,
									name: 'personal_fee_3',
									type: 'text',
									placeholder:
										'Hệ thống tính số tiền được hoàn trả',
									label: 'Thành tiền',
									value: '',
									disabled: true,
								},
							],
						},
						{
							id: 2,
							title: 'Học can thiệp lớp nhóm',
							disabled: false,
							fields: [
								{
									id: 1,
									type: 'number',
									placeholder: 'Nhập chi phí',
									label: 'Chi phí hoàn',
									value: '',
									disabled: true,
								},
								{
									id: 2,
									type: 'text',
									placeholder:
										'Hệ thống đếm số giờ nghỉ của học sinh',
									label: 'Số lượng',
									value: '',
									disabled: true,
								},
								{
									id: 3,
									type: 'text',
									placeholder:
										'Hệ thống tính số tiền được hoàn trả',
									label: 'Thành tiền',
									value: '',
									disabled: true,
								},
							],
						},
						{
							id: 3,
							title: 'Học bán trú',
							disabled: false,
							fields: [
								{
									id: 1,
									type: 'number',
									placeholder: 'Nhập chi phí',
									label: 'Chi phí hoàn',
									value: '',
									disabled: true,
								},
								{
									id: 2,
									type: 'text',
									placeholder:
										'Hệ thống đếm số giờ nghỉ của học sinh',
									label: 'Số lượng',
									value: '',
									disabled: true,
								},
								{
									id: 3,
									type: 'text',
									placeholder:
										'Hệ thống tính số tiền được hoàn trả',
									label: 'Thành tiền',
									value: '',
									disabled: true,
								},
							],
						},
					],
				},
			],
			studentForm: {
				content_of_notice:
					'Dolore esse et ullam id. 27 Tháng 8/2024 của Shaun 774',
				data: {
					fee: {
						default: [
							{
								count: 6,
								label: 'personal',
								name: 'Học can thiệp cá nhân',
								price: 200000,
								total: 1200000,
							},
							{
								count: 1,
								label: 'group_class',
								name: 'Học can thiệp lớp nhóm',
								price: 300000,
								total: 300000,
							},
							{
								count: 6,
								label: 'day_care',
								name: 'Học bán trú',
								price: 100000,
								total: 600000,
							},
							{
								count: 1,
								label: 'app',
								name: 'Phí sử dụng sổ nhật ký điện tử',
								price: 68000,
								total: 68000,
							},
						],
						other: [],
						total: 2500000,
					},
					refund: {
						default: [
							{
								count: 1,
								label: 'personal',
								name: 'Học can thiệp cá nhân',
								price: 200000,
								total: 200000,
							},
							{
								count: 1,
								label: 'group_class',
								name: 'Học can thiệp lớp nhóm',
								price: 300000,
								total: 300000,
							},
							{
								count: 1,
								label: 'day_care',
								name: 'Học bán trú',
								price: 100000,
								total: 100000,
							},
						],
						other: [],
						total: 0,
					},
					total: 2500000,
				},
				day_care_class: {
					id: 51257,
					name: 'Lớp Tre Xanh',
				},
				group_classes: [
					{
						enabled: true,
						id: 52077,
						name: 'Lớp nhóm hè 2024',
					},
					{
						enabled: true,
						id: 52078,
						name: 'Lớp nhóm trẻ dưới 6 tuổi',
					},
				],
				student: {
					age: '7 tuổi 6 tháng',
					id: 257162,
					name: 'A Nhược',
					profile_photo: {
						default: {
							color: '#F1A267',
							initial: 'S7',
						},
						state: 'active',
					},
				},
				title: 'Tên biểu mẫu 1',
				title_of_notice: 'Thông báo học phí Tháng 9 / 2024',
			},
		};
	},

	computed: {
		dayjs() {
			return dayjs;
		},
		id() {
			return this.$route.params.id || '';
		},

		type() {
			return ACTIONS;
		},

		isAdmin() {
			return this.$store.getters.isAdmin;
		},

		currentOrg() {
			return this.$store.state.profile.current_org;
		},
	},

	created() {
		this.fetchNotification();
	},

	mounted() {
		this.studentForm?.data?.fee?.default.forEach((item, index) => {
			this.forms[0].cards[index].fields[0].value = item.price;
			this.forms[0].cards[index].fields[1].value = item.count;
			this.forms[0].cards[index].fields[2].value = item.total;
		});

		this.studentForm?.data?.refund?.default.forEach((item, index) => {
			this.forms[1].cards[index].fields[0].value = item.price;
			this.forms[1].cards[index].fields[1].value = item.count;
			this.forms[1].cards[index].fields[2].value = item.total;
		});
	},

	methods: {
		async fetchNotification() {
			try {
				const res = await $api.notification.getFeeNotification(this.id);
				this.notification = res?.data?.data;
				this.files =
					this.notification.notice?.notice_attachments?.filter(
						(i) => !i.is_image
					) || [];
				this.images =
					this.notification.notice?.notice_attachments?.filter(
						(i) => i.is_image
					) || [];

				if (
					this.notification.notice?.attachment_photo &&
					this.notification.notice?.attachment_photo?.w1000
				) {
					this.images.push({
						id: this.notification.notice?.attachment_photo?.w1000,
						asset_key:
							this.notification.notice?.attachment_photo?.w1000,
						asset_url:
							this.notification.notice?.attachment_photo?.w1000,
					});
				}
				if (
					this.notification.notice?.attachment_file &&
					this.notification.notice?.attachment_file?.file?.original
				) {
					this.files.push({
						id: this.notification.notice?.attachment_file?.file
							?.original,
						asset_key:
							this.notification.notice?.attachment_file?.file
								?.original,
						asset_url:
							this.notification.notice?.attachment_file?.file
								?.original,
						name: this.notification.notice?.attachment_file
							?.file_name,
					});
				}
				await this.fetchParentReadNotification();
			} catch (err) {
				console.log(err);
			}
		},

		async deleteNotification() {
			this.inProgress = true;
			try {
				await $api.notification.deleteFeeNotification(this.id);
				await this.$router.push('/fee-notices');
				this.$store.dispatch('setAlertMessage', {
					message: 'Xoá mới thông báo thành công',
				});
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.inProgress = false;
			}
		},

		deleteOpen() {
			this.modalIsOpen = true;
			this.modalType = this.type.DELETE;
		},

		async fetchParentReadNotification() {
			try {
				const {
					data: { data },
				} = await $api.notification.historySendFeeNotification(
					this.notification.tuition_notice_id
				);
				this.parents = data;
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		},

		async sendNotification() {
			this.inProgress = true;
			try {
				await $api.notification.sendFeeNotification(this.id);
				this.cancel();
				await this.fetchNotification();
				this.$store.dispatch('setAlertMessage', {
					message: 'Gửi thông báo thành công',
				});
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.inProgress = false;
			}
		},

		confirmSendOpen() {
			this.modalIsOpen = true;
			this.modalType = this.type.CREATE;
			this.notificationConfirm = {
				content: this.notification.notice?.content,
				title: this.notification.notice?.title,
				month: this.notification.month,
				student: this.notification.student,
				files: this.files,
				images: this.images,
			};
		},

		confirmResendOpen() {
			if (this.notification.lastest_notice_sent_at) {
				this.lastSendAt = timeSince(
					this.notification.lastest_notice_sent_at
				);
			}
			this.modalIsOpen = true;
			this.modalType = this.type.UPDATE;
		},

		async reSendNotification() {
			this.inProgress = true;
			try {
				await $api.notification.reSendFeeNotification(
					this.notification.tuition_notice_id
				);
				this.cancel();
				await this.fetchNotification();
				await this.fetchParentReadNotification();
				this.$store.dispatch('setAlertMessage', {
					message: 'Gửi thông báo thành công',
				});
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.inProgress = false;
			}
		},

		cancel() {
			this.modalIsOpen = false;
			this.modalType = '';
		},

		openMediaPopup(index) {
			this.imageShow = true;
			this.photosIndex = index;
		},
	},
};
</script>
<style lang="scss" scoped>
.title {
	font-weight: 600;
	box-shadow: none !important;

	&::placeholder {
		font-weight: normal;
	}
}
</style>
