<template>
	<ViewPhoto
		v-if="imageShow && images.length"
		:key="photosIndex"
		:images="images"
		:index="photosIndex"
		@close="imageShow = false"
	/>
	<DaModalFrame v-model:open="modalIsOpen">
		<ConfirmSendFeeNotice
			v-if="modalType === type.CREATE && notificationConfirm"
			:in-progress="inProgress"
			:notification="{
				...notificationConfirm,
				notice: {
					...notificationConfirm.notice,
					files,
					images,
				},
			}"
			is-network
			@close="cancel"
			@openViewPhoto="openMediaPopup(0)"
			@submit="sendNotification"
		/>
		<ConfirmResend
			v-if="modalType === type.UPDATE"
			:times="lastSendAt"
			@close="cancel"
			@submit="reSendNotification"
		/>
		<DeleteConfirm
			v-if="modalType === type.DELETE"
			@close="cancel"
			@submit="deleteNotification"
		/>
	</DaModalFrame>
	<div class="pb-30 px-10 md-down:px-4">
		<div
			class="
				__report-lesson__header
				pt-10
				pb-10
				flex flex-nowrap
				justify-between
				items-center
			"
		>
			<div
				class="
					__record__title
					text-neutral-500
					font-semibold
					whitespace-nowrap
					flex flex-nowrap
					items-center
					text-4xl
					cursor-pointer
				"
				@click="$router.push('/fee-notices')"
			>
				<svg
					class="mr-2"
					fill="none"
					height="28"
					viewBox="0 0 28 28"
					width="28"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M17.5 7L10.5 14L17.5 21"
						stroke="#94A3B8"
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
					/>
				</svg>
				Chi tiết thông báo học phí
			</div>
			<div
				v-if="notification.notice.state === 'draft'"
				class="flex items-center justify-end gap-6"
			>
				<div
					class="
						flex
						items-center
						text-yellow-600
						hover:text-yellow-700
						font-semibold
						cursor-pointer
						text-sm
					"
					@click="deleteOpen"
				>
					<svg
						class="mr-2"
						fill="none"
						height="16"
						viewBox="0 0 16 16"
						width="16"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							clip-rule="evenodd"
							d="M2.5 1C1.94772 1 1.5 1.44772 1.5 2V3C1.5 3.55228 1.94772 4 2.5 4H3V13C3 14.1046 3.89543 15 5 15H11C12.1046 15 13 14.1046 13 13V4H13.5C14.0523 4 14.5 3.55228 14.5 3V2C14.5 1.44772 14.0523 1 13.5 1H10C10 0.447715 9.55229 0 9 0H7C6.44772 0 6 0.447715 6 1H2.5ZM5.5 5C5.77614 5 6 5.22386 6 5.5V12.5C6 12.7761 5.77614 13 5.5 13C5.22386 13 5 12.7761 5 12.5L5 5.5C5 5.22386 5.22386 5 5.5 5ZM8 5C8.27614 5 8.5 5.22386 8.5 5.5V12.5C8.5 12.7761 8.27614 13 8 13C7.72386 13 7.5 12.7761 7.5 12.5V5.5C7.5 5.22386 7.72386 5 8 5ZM11 5.5C11 5.22386 10.7761 5 10.5 5C10.2239 5 10 5.22386 10 5.5V12.5C10 12.7761 10.2239 13 10.5 13C10.7761 13 11 12.7761 11 12.5V5.5Z"
							fill="currentColor"
							fill-rule="evenodd"
						/>
					</svg>
					<span class="mt-0.5">Xoá</span>
				</div>
				<div
					class="
						flex
						items-center
						text-yellow-600
						hover:text-yellow-700
						font-semibold
						cursor-pointer
						text-sm
					"
					@click="$router.push(`/fee-notices/${id}/edit`)"
				>
					<svg
						class="mr-2"
						fill="none"
						height="16"
						viewBox="0 0 16 16"
						width="16"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							clip-rule="evenodd"
							d="M12.4702 6.65078L13.7102 5.41075C13.8461 5.27489 13.914 5.20696 13.9667 5.14499C14.6009 4.39832 14.6009 3.30214 13.9667 2.55546C13.914 2.4935 13.8461 2.42557 13.7102 2.28971C13.5744 2.15385 13.5064 2.08592 13.4445 2.03329C12.6978 1.39906 11.6016 1.39906 10.855 2.03329C10.793 2.08591 10.7251 2.15382 10.5893 2.28961L10.5892 2.28969L10.5892 2.28971L9.33099 3.54792C10.0747 4.84212 11.1583 5.91739 12.4702 6.65078ZM7.87633 5.00258L2.86273 10.0162C2.43767 10.4412 2.22514 10.6538 2.0854 10.9149C1.94567 11.176 1.88672 11.4707 1.76883 12.0601L1.37318 14.0384C1.30666 14.371 1.27339 14.5373 1.368 14.6319C1.46261 14.7266 1.62892 14.6933 1.96153 14.6268L3.9398 14.2311L3.93982 14.2311L3.93984 14.2311C4.52927 14.1132 4.82399 14.0543 5.08508 13.9145C5.34617 13.7748 5.55869 13.5623 5.98373 13.1373L5.98376 13.1372L5.98377 13.1372L11.0118 8.10922C9.7511 7.31101 8.68202 6.24913 7.87633 5.00258Z"
							fill="currentColor"
							fill-rule="evenodd"
						/>
					</svg>
					<span class="mt-0.5">Chỉnh sửa</span>
				</div>
			</div>
			<div v-else>
				<button
					class="
						h-12
						button-p
						btn--ghost
						text-center
						mr-3
						px-6
						whitespace-nowrap
					"
					type="button"
					@click.stop="$router.push('/fee-notices/create')"
				>
					Tạo thông báo mới
				</button>
			</div>
		</div>
		<div class="bg-white rounded-xl p-6 shadow-sm">
			<div class="flex justify-between items-center">
				<div class="text-gray-600 font-semibold text-lg">
					Nội dung thông báo
				</div>
				<div class="flex items-center justify-end gap-8">
					<div
						v-if="notification.notice.state === 'draft'"
						class="
							w-30
							bg-white
							border-neutral-200
							cursor-pointer
							flex
							items-center
							md-down:mt-6
						"
					>
						<svg
							fill="none"
							height="20"
							viewBox="0 0 20 20"
							width="20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								clip-rule="evenodd"
								d="M2.49992 0C1.57944 0 0.833252 0.746192 0.833252 1.66667V18.3333C0.833252 19.2538 1.57944 20 2.49992 20H10.5902C9.20618 18.7784 8.33325 16.9231 8.33325 14.9319C8.33325 11.25 11.318 8.33333 14.9999 8.33333C15.884 8.33333 16.7279 8.50542 17.4999 8.81793V1.66667C17.4999 0.746192 16.7537 0 15.8333 0H2.49992ZM2.49992 7.08333C2.2698 7.08333 2.08325 7.26988 2.08325 7.5C2.08325 7.73012 2.2698 7.91667 2.49992 7.91667H10.4166C10.6467 7.91667 10.8333 7.73012 10.8333 7.5C10.8333 7.26988 10.6467 7.08333 10.4166 7.08333H2.49992ZM2.49992 9.58333C2.2698 9.58333 2.08325 9.76988 2.08325 10C2.08325 10.2301 2.2698 10.4167 2.49992 10.4167H8.74992C8.98004 10.4167 9.16658 10.2301 9.16658 10C9.16658 9.76988 8.98004 9.58333 8.74992 9.58333H2.49992ZM2.49992 12.0833C2.2698 12.0833 2.08325 12.2699 2.08325 12.5C2.08325 12.7301 2.2698 12.9167 2.49992 12.9167H7.08325C7.31337 12.9167 7.49992 12.7301 7.49992 12.5C7.49992 12.2699 7.31337 12.0833 7.08325 12.0833H2.49992ZM2.49992 14.5833C2.2698 14.5833 2.08325 14.7699 2.08325 15C2.08325 15.2301 2.2698 15.4167 2.49992 15.4167H5.41658C5.6467 15.4167 5.83325 15.2301 5.83325 15C5.83325 14.7699 5.6467 14.5833 5.41658 14.5833H2.49992ZM2.91659 2.91667C2.45635 2.91667 2.08325 3.28976 2.08325 3.75C2.08325 4.21024 2.45635 4.58333 2.91659 4.58333H14.1666C14.6268 4.58333 14.9999 4.21024 14.9999 3.75C14.9999 3.28976 14.6268 2.91667 14.1666 2.91667H2.91659Z"
								fill="#94A3B8"
								fill-rule="evenodd"
							/>
							<path
								clip-rule="evenodd"
								d="M19.9999 15C19.9999 17.7614 17.7613 20 14.9999 20C12.2385 20 9.99992 17.7614 9.99992 15C9.99992 12.2386 12.2385 10 14.9999 10C17.7613 10 19.9999 12.2386 19.9999 15ZM13.3459 12.904C13.2239 12.782 13.026 12.782 12.9039 12.904C12.7819 13.0261 12.7819 13.2239 12.9039 13.346L14.558 15L12.9039 16.654C12.7819 16.7761 12.7819 16.9739 12.9039 17.096C13.026 17.218 13.2239 17.218 13.3459 17.096L14.9999 15.4419L16.6539 17.096C16.776 17.218 16.9739 17.218 17.0959 17.096C17.2179 16.9739 17.2179 16.7761 17.0959 16.654L15.4419 15L17.0959 13.346C17.2179 13.2239 17.2179 13.0261 17.0959 12.904C16.9739 12.782 16.776 12.782 16.6539 12.904L14.9999 14.5581L13.3459 12.904Z"
								fill="#94A3B8"
								fill-rule="evenodd"
							/>
						</svg>
						<div
							class="
								ml-2
								text-sm text-neutral-600
								whitespace-nowrap
							"
						>
							Bản nháp
						</div>
					</div>
					<div
						v-else
						class="
							w-30
							bg-white
							border-neutral-200
							flex
							items-center
							md-down:mt-6
						"
					>
						<svg
							fill="none"
							height="20"
							viewBox="0 0 20 20"
							width="20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M17.4729 3.29087L12.482 17.2255C12.4224 17.3902 12.2661 17.4999 12.091 17.5H11.9579C11.7946 17.5 11.6458 17.4061 11.5753 17.2587L9.61218 13.0992C9.32311 12.4859 9.435 11.7586 9.895 11.2606L12.3905 8.53194C12.5436 8.37126 12.5436 8.1186 12.3905 7.95792L12.0744 7.64179C11.9137 7.48861 11.6611 7.48861 11.5004 7.64179L8.77204 10.1375C8.27411 10.5976 7.54687 10.7095 6.9337 10.4204L2.77457 8.45707C2.61418 8.39667 2.50588 8.24569 2.50007 8.07439V7.94128C2.48534 7.7541 2.59781 7.58026 2.77457 7.517L16.7077 2.5255C16.8575 2.47049 17.0255 2.50605 17.1402 2.61701L17.3565 2.83331C17.4865 2.94689 17.5328 3.12896 17.4729 3.29087Z"
								fill="#2AA797"
							/>
						</svg>
						<div
							class="
								ml-2
								text-sm text-neutral-600
								whitespace-nowrap
							"
						>
							Thông báo đã gửi
						</div>
					</div>
				</div>
			</div>
			<div class="rounded-lg p-4 border border-gray-100 mt-4">
				<div class="flex items-center justify-between">
					<div
						class="
							uppercase
							text-neutral-400 text-sm
							font-medium
							tracking-wide
							w-60
						"
					>
						Tiêu đề
					</div>
					<div class="w-full font-semibold">
						{{ studentForm.title }}
					</div>
				</div>
				<div class="mt-6 flex items-center justify-between">
					<div
						class="
							uppercase
							text-neutral-400 text-sm
							font-medium
							tracking-wide
							w-60
						"
					>
						Tên học sinh
					</div>
					<div class="w-full">{{ studentForm?.student?.name }}</div>
				</div>
				<div class="mt-6 flex items-center justify-between">
					<div
						class="
							uppercase
							text-neutral-400 text-sm
							font-medium
							tracking-wide
							w-60
						"
					>
						Lớp bán trú
					</div>
					<div class="w-full">
						{{ studentForm.day_care_class?.name }}
					</div>
				</div>
				<div class="mt-6 flex justify-between">
					<div
						class="
							uppercase
							text-neutral-400 text-sm
							font-medium
							tracking-wide
							w-60
						"
					>
						Lớp nhóm
					</div>
					<div class="w-full">
						<div
							v-for="gClass in studentForm.group_classes"
							:key="gClass.id"
						>
							Lớp nhóm hè 2024
						</div>
					</div>
				</div>
			</div>
			<div class="mt-4">
				<div v-for="(form, i) in forms" :key="form.id">
					<div class="w-fit min-w-full">
						<table class="min-w-full text-neutral-200">
							<thead class="h-12">
								<tr>
									<th
										class="
											px-3
											py-3
											text-center text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											w-16
											whitespace-nowrap
											border
										"
										scope="col"
									>
										STT
									</th>
									<th
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
											border
										"
										scope="col"
									>
										Khoản thu
									</th>
									<th
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
											border
										"
										scope="col"
									>
										Chi phí (VNĐ)
									</th>
									<th
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
											border
										"
										scope="col"
									>
										Số lượng
									</th>
									<th
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
											border
										"
										scope="col"
									>
										Thành tiền (VNĐ)
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(item, index) in form.cards"
									:key="item.id"
									class="text-neutral-700"
								>
									<td class="border">
										<div
											class="
												px-3
												py-4
												text-center text-neutral-700
											"
										>
											{{ index + 1 }}
										</div>
									</td>
									<td
										class="
											px-3
											py-4
											text-neutral-700
											cursor-pointer
											border
										"
										style="max-width: 200px"
									>
										<div>
											{{ item?.title }}
										</div>
									</td>
									<td
										class="
											px-3
											py-4
											whitespace-nowrap
											text-neutral-700
											border
										"
									>
										<div>
											{{
												$filters.priceFormat(
													item.canDelete
														? item.fields[1].value
														: item.fields[0].value
												)
											}}
										</div>
									</td>
									<td
										class="
											px-3
											py-4
											whitespace-nowrap
											text-neutral-700
											border
										"
									>
										{{
											$filters.priceFormat(
												item.canDelete
													? item.fields[2].value
													: item.fields[1].value
											)
										}}
									</td>
									<td
										class="
											px-3
											py-4
											whitespace-nowrap
											text-neutral-700
											border
										"
									>
										{{
											$filters.priceFormat(
												item.canDelete
													? item.fields[3].value
													: item.fields[2].value
											)
										}}
									</td>
								</tr>
							</tbody>
						</table>
						<div
							class="
								capitalize
								h-12
								flex
								items-center
								justify-between
								bg-neutral-100
								border-l border-r
								px-4
								font-semibold
								text-neutral-500
							"
						>
							<div>Tổng {{ form.title }}</div>
							<div>
								{{
									$filters.priceFormat(
										studentForm?.data[
											i === 0 ? 'fee' : 'refund'
										]?.total
									)
								}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				class="
					capitalize
					h-12
					flex
					items-center
					justify-between
					border
					px-4
					font-semibold
					text-lg text-neutral-500
				"
			>
				<div>Tổng chi phí phụ huynh phải đóng</div>
				<div>{{ $filters.priceFormat(studentForm.data.total) }}</div>
			</div>
		</div>
		<div
			v-if="notification.notice.state === 'draft'"
			class="bg-white rounded-xl p-6 shadow-sm mt-10"
		>
			<div class="rounded-lg p-4 border border-gray-100 mt-4">
				<div class="flex md-down:flex-col w-full gap-4">
					<div
						class="
							uppercase
							text-neutral-400 text-sm
							font-medium
							tracking-wide
							w-60
						"
					>
						gửi lúc
					</div>
					<div class="w-full text-gray-700">
						<div v-if="notification.notice?.sent_at">
							{{
								$filters.formatDateTime(
									notification.notice?.sent_at
								)
							}}
						</div>
						<div v-else>-</div>
					</div>
				</div>
				<div class="flex md-down:flex-col w-full gap-4 mt-8">
					<div
						class="
							uppercase
							text-neutral-400 text-sm
							font-medium
							tracking-wide
							w-60
						"
					>
						tạo lúc
					</div>
					<div class="w-full text-gray-700">
						<div v-if="notification.notice?.inserted_at">
							{{
								$filters.formatDateTime(
									notification.notice?.inserted_at
								)
							}}
						</div>
						<div v-else>-</div>
					</div>
				</div>
				<div class="flex md-down:flex-col w-full gap-4 mt-8">
					<div
						class="
							uppercase
							text-neutral-400 text-sm
							font-medium
							tracking-wide
							w-60
						"
					>
						người gửi
					</div>
					<div class="w-full text-gray-700">
						<div
							v-if="notification.notice.sent_by_staff"
							:class="{
								'cursor-pointer': isAdmin,
							}"
							@click="
								isAdmin &&
									$router.push(
										`/record/employee/${notification.notice.sent_by_staff.id}`
									)
							"
						>
							<div class="flex items-center">
								<img
									v-if="
										notification.notice.sent_by_staff
											?.profile_photo?.w200
									"
									:src="
										notification.notice.sent_by_staff
											?.profile_photo?.w200
									"
									alt=""
									class="
										shadow-sm
										inline-block
										h-6
										w-6
										rounded-full
									"
								/>
								<span
									v-else
									:style="{
										background:
											notification.notice.sent_by_staff
												?.profile_photo?.default?.color,
									}"
									class="
										inline-flex
										items-center
										justify-center
										h-6
										w-6
										rounded-full
									"
								>
									<span
										class="
											font-medium
											leading-none
											text-white text-xs
										"
										>{{
											notification.notice.sent_by_staff
												?.profile_photo?.default
												?.initial
										}}</span
									>
								</span>
								<div class="ml-4 text-blue-600 font-semibold">
									{{
										notification.notice.sent_by_staff?.name
									}}
								</div>
							</div>
						</div>
						<div v-else>-</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-if="notification.notice.state === 'draft'"
			class="mt-10 flex items-center justify-center md:justify-end"
		>
			<button
				class="button-p text-center w-fit px-10"
				type="button"
				@click="confirmSendOpen"
			>
				Gửi thông báo
			</button>
		</div>
		<div
			v-else
			class="mt-10 flex items-center justify-center md:justify-end"
		>
			<button
				class="button-p text-center w-fit px-10"
				type="button"
				@click="confirmResendOpen"
			>
				Gửi lại thông báo
			</button>
		</div>
		<div class="text-lg text-neutral-600 font-semibold my-6">
			Lịch sử gửi thông báo học phí
		</div>
		<div class="shadow-sm sm:rounded-2xl">
			<div class="__record__table overflow-x-auto scroll-bar">
				<div class="overflow-hidden sm:rounded-t-2xl w-fit min-w-full">
					<table class="min-w-full divide-y text-neutral-200">
						<thead class="bg-neutral-100 h-16">
							<tr>
								<th
									class="
										px-6
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										w-16
										whitespace-nowrap
									"
									scope="col"
								>
									STT
								</th>
								<th
									class="
										px-6
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
										w-32
									"
									scope="col"
								>
									người gửi
								</th>
								<th
									class="
										px-6
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
									"
									scope="col"
								>
									gửi lúc
								</th>
								<th
									class="
										px-6
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
									"
									scope="col"
								>
									Phụ Huynh đã xem
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(person, personIdx) in parents"
								:key="person.id"
								:class="
									personIdx % 2 === 0
										? 'bg-white'
										: 'bg-neutral-50'
								"
								class="text-neutral-700"
							>
								<td>
									<div class="px-6 py-4 text-neutral-700">
										{{ personIdx + 1 }}
									</div>
								</td>
								<td
									class="
										px-6
										py-4
										whitespace-nowrap
										text-neutral-700
									"
								>
									<div class="w-full text-gray-700">
										<div
											v-if="
												notification.notice
													.sent_by_staff
											"
											:class="{
												'cursor-pointer': isAdmin,
											}"
											@click="
												isAdmin &&
													$router.push(
														`/record/employee/${person.sent_by_staff.id}`
													)
											"
										>
											<div class="flex items-center">
												<img
													v-if="
														person.sent_by_staff
															?.profile_photo
															?.w200
													"
													:src="
														person.sent_by_staff
															?.profile_photo
															?.w200
													"
													alt=""
													class="
														shadow-sm
														inline-block
														h-6
														w-6
														rounded-full
													"
												/>
												<span
													v-else
													:style="{
														background:
															person.sent_by_staff
																?.profile_photo
																?.default
																?.color,
													}"
													class="
														inline-flex
														items-center
														justify-center
														h-6
														w-6
														rounded-full
													"
												>
													<span
														class="
															font-medium
															leading-none
															text-white text-xs
														"
														>{{
															person.sent_by_staff
																?.profile_photo
																?.default
																?.initial
														}}</span
													>
												</span>
												<div
													class="
														ml-4
														text-blue-600
														font-semibold
													"
												>
													{{
														person.sent_by_staff
															?.name
													}}
												</div>
											</div>
										</div>
										<div v-else>-</div>
									</div>
								</td>
								<td
									class="
										px-6
										py-4
										whitespace-nowrap
										text-neutral-700
									"
								>
									<div v-if="person?.sent_at">
										{{
											$filters.formatDateTime(
												person?.sent_at
											)
										}}
									</div>
									<div v-else>-</div>
								</td>
								<td
									class="px-6 py-4 whitespace-nowrap"
									style="width: 300px"
								>
									{{ person.view_count || 0 }} /
									{{ person.total_parent_received || 0 }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { $api } from '@/services';
import { ACTIONS } from '@/enums/Common';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame';
import ConfirmSendFeeNotice from '@/views/feeNoticesCreate/ConfirmSendFeeNotice';
import ConfirmResend from '@/views/feeNotoceDetail/features/ConfirmResend';
import DeleteConfirm from '@/views/notificationDetail/features/DeleteConfirm';
import { timeSince } from '@/helper/formatDate';
import ViewPhoto from '@/components/common/ViewPhoto';

export default {
	name: 'FeeNoticeDetail',
	components: {
		ViewPhoto,
		DeleteConfirm,
		ConfirmResend,
		ConfirmSendFeeNotice,
		DaModalFrame,
	},
	data() {
		return {
			modalIsOpen: false,
			modalType: false,
			notificationConfirm: null,
			notification: {
				inserted_at: null,
				month: '',
				notice: {},
				org_name: '',
				student: {},
			},
			files: [],
			images: [],
			file: null,
			parents: [],
			lastSendAt: '',
			inProgress: false,
			imageShow: false,
			photosIndex: 0,
			forms: [
				{
					id: 1,
					title: 'Khoản thu',
					cards: [
						{
							id: 1,
							title: 'Học can thiệp cá nhân',
							disabled: false,
							fields: [
								{
									id: 1,
									type: 'number',
									placeholder: 'Nhập chi phí',
									label: 'Chi phí thu',
									value: '',
									disabled: true,
								},
								{
									id: 2,
									type: 'text',
									placeholder:
										'Hệ thống đếm số giờ học của học sinh',
									label: 'Số lượng',
									value: '',
									disabled: true,
								},
								{
									id: 3,
									name: 'personal_fee_3',
									type: 'text',
									placeholder:
										'Hệ thống tính số tiền phải đóng',
									label: 'Thành tiền',
									value: '',
									disabled: true,
								},
							],
						},
						{
							id: 2,
							title: 'Học can thiệp lớp nhóm',
							disabled: false,
							fields: [
								{
									id: 1,
									type: 'number',
									placeholder: 'Nhập chi phí',
									label: 'Chi phí thu',
									value: '',
									disabled: true,
								},
								{
									id: 2,
									type: 'text',
									placeholder:
										'Hệ thống đếm số giờ học của học sinh',
									label: 'Số lượng',
									value: '',
									disabled: true,
								},
								{
									id: 3,
									type: 'text',
									placeholder:
										'Hệ thống tính số tiền phải đóng',
									label: 'Thành tiền',
									value: '',
									disabled: true,
								},
							],
						},
						{
							id: 3,
							title: 'Học bán trú',
							disabled: false,
							fields: [
								{
									id: 1,
									type: 'number',
									placeholder: 'Nhập chi phí',
									label: 'Chi phí thu',
									value: '',
									disabled: true,
								},
								{
									id: 2,
									type: 'text',
									placeholder:
										'Hệ thống đếm số giờ học của học sinh',
									label: 'Số lượng',
									value: '',
									disabled: true,
								},
								{
									id: 3,
									type: 'text',
									placeholder:
										'Hệ thống tính số tiền phải đóng',
									label: 'Thành tiền',
									value: '',
									disabled: true,
								},
							],
						},
						{
							id: 4,
							title: 'Phí sử dụng sổ nhật ký điện tử',
							disabled: false,
							fields: [
								{
									id: 1,
									type: 'number',
									placeholder: 'Nhập chi phí',
									label: 'Chi phí thu',
									value: '',
									disabled: true,
								},
								{
									id: 2,
									type: 'text',
									placeholder:
										'Hệ thống đếm số giờ học của học sinh',
									label: 'Số lượng',
									value: '',
									disabled: true,
								},
								{
									id: 3,
									type: 'text',
									placeholder:
										'Hệ thống tính số tiền phải đóng',
									label: 'Thành tiền',
									value: '',
									disabled: true,
								},
							],
						},
					],
				},
				{
					id: 2,
					title: 'Hoàn phí',
					cards: [
						{
							id: 1,
							title: 'Học can thiệp cá nhân',
							disabled: false,
							fields: [
								{
									id: 1,
									type: 'number',
									placeholder: 'Nhập chi phí',
									label: 'Chi phí hoàn',
									value: '',
									disabled: true,
								},
								{
									id: 2,
									type: 'text',
									placeholder:
										'Hệ thống đếm số giờ nghỉ của học sinh',
									label: 'Số lượng',
									value: '',
									disabled: true,
								},
								{
									id: 3,
									name: 'personal_fee_3',
									type: 'text',
									placeholder:
										'Hệ thống tính số tiền được hoàn trả',
									label: 'Thành tiền',
									value: '',
									disabled: true,
								},
							],
						},
						{
							id: 2,
							title: 'Học can thiệp lớp nhóm',
							disabled: false,
							fields: [
								{
									id: 1,
									type: 'number',
									placeholder: 'Nhập chi phí',
									label: 'Chi phí hoàn',
									value: '',
									disabled: true,
								},
								{
									id: 2,
									type: 'text',
									placeholder:
										'Hệ thống đếm số giờ nghỉ của học sinh',
									label: 'Số lượng',
									value: '',
									disabled: true,
								},
								{
									id: 3,
									type: 'text',
									placeholder:
										'Hệ thống tính số tiền được hoàn trả',
									label: 'Thành tiền',
									value: '',
									disabled: true,
								},
							],
						},
						{
							id: 3,
							title: 'Học bán trú',
							disabled: false,
							fields: [
								{
									id: 1,
									type: 'number',
									placeholder: 'Nhập chi phí',
									label: 'Chi phí hoàn',
									value: '',
									disabled: true,
								},
								{
									id: 2,
									type: 'text',
									placeholder:
										'Hệ thống đếm số giờ nghỉ của học sinh',
									label: 'Số lượng',
									value: '',
									disabled: true,
								},
								{
									id: 3,
									type: 'text',
									placeholder:
										'Hệ thống tính số tiền được hoàn trả',
									label: 'Thành tiền',
									value: '',
									disabled: true,
								},
							],
						},
					],
				},
			],
			studentForm: {
				content_of_notice:
					'Dolore esse et ullam id. 27 Tháng 8/2024 của Shaun 774',
				data: {
					fee: {
						default: [
							{
								count: 6,
								label: 'personal',
								name: 'Học can thiệp cá nhân',
								price: 200000,
								total: 1200000,
							},
							{
								count: 1,
								label: 'group_class',
								name: 'Học can thiệp lớp nhóm',
								price: 300000,
								total: 300000,
							},
							{
								count: 6,
								label: 'day_care',
								name: 'Học bán trú',
								price: 100000,
								total: 600000,
							},
							{
								count: 1,
								label: 'app',
								name: 'Phí sử dụng sổ nhật ký điện tử',
								price: 68000,
								total: 68000,
							},
						],
						other: [],
						total: 2500000,
					},
					refund: {
						default: [
							{
								count: 1,
								label: 'personal',
								name: 'Học can thiệp cá nhân',
								price: 200000,
								total: 200000,
							},
							{
								count: 1,
								label: 'group_class',
								name: 'Học can thiệp lớp nhóm',
								price: 300000,
								total: 300000,
							},
							{
								count: 1,
								label: 'day_care',
								name: 'Học bán trú',
								price: 100000,
								total: 100000,
							},
						],
						other: [],
						total: 0,
					},
					total: 2500000,
				},
				day_care_class: {
					id: 51257,
					name: 'Lớp Tre Xanh',
				},
				group_classes: [
					{
						enabled: true,
						id: 52077,
						name: 'Lớp nhóm hè 2024',
					},
					{
						enabled: true,
						id: 52078,
						name: 'Lớp nhóm trẻ dưới 6 tuổi',
					},
				],
				student: {
					age: '7 tuổi 6 tháng',
					id: 257162,
					name: 'A Nhược',
					profile_photo: {
						default: {
							color: '#F1A267',
							initial: 'S7',
						},
						state: 'active',
					},
				},
				title: 'Tên biểu mẫu 1',
				title_of_notice: 'Thông báo học phí Tháng 9 / 2024',
			},
		};
	},

	computed: {
		id() {
			return this.$route.params.id || '';
		},

		type() {
			return ACTIONS;
		},

		isAdmin() {
			return this.$store.getters.isAdmin;
		},
	},

	created() {
		this.fetchNotification();
	},

	mounted() {
		this.studentForm?.data?.fee?.default.forEach((item, index) => {
			this.forms[0].cards[index].fields[0].value = item.price;
			this.forms[0].cards[index].fields[1].value = item.count;
			this.forms[0].cards[index].fields[2].value = item.total;
		});

		this.studentForm?.data?.refund?.default.forEach((item, index) => {
			this.forms[1].cards[index].fields[0].value = item.price;
			this.forms[1].cards[index].fields[1].value = item.count;
			this.forms[1].cards[index].fields[2].value = item.total;
		});

		console.log(this.forms);
	},

	methods: {
		async fetchNotification() {
			try {
				const res = await $api.notification.getFeeNotification(this.id);
				this.notification = res?.data?.data;
				this.files =
					this.notification.notice?.notice_attachments?.filter(
						(i) => !i.is_image
					) || [];
				this.images =
					this.notification.notice?.notice_attachments?.filter(
						(i) => i.is_image
					) || [];

				if (
					this.notification.notice?.attachment_photo &&
					this.notification.notice?.attachment_photo?.w1000
				) {
					this.images.push({
						id: this.notification.notice?.attachment_photo?.w1000,
						asset_key:
							this.notification.notice?.attachment_photo?.w1000,
						asset_url:
							this.notification.notice?.attachment_photo?.w1000,
					});
				}
				if (
					this.notification.notice?.attachment_file &&
					this.notification.notice?.attachment_file?.file?.original
				) {
					this.files.push({
						id: this.notification.notice?.attachment_file?.file
							?.original,
						asset_key:
							this.notification.notice?.attachment_file?.file
								?.original,
						asset_url:
							this.notification.notice?.attachment_file?.file
								?.original,
						name: this.notification.notice?.attachment_file
							?.file_name,
					});
				}
				await this.fetchParentReadNotification();
			} catch (err) {
				console.log(err);
			}
		},

		async deleteNotification() {
			this.inProgress = true;
			try {
				await $api.notification.deleteFeeNotification(this.id);
				await this.$router.push('/fee-notices');
				this.$store.dispatch('setAlertMessage', {
					message: 'Xoá mới thông báo thành công',
				});
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.inProgress = false;
			}
		},

		deleteOpen() {
			this.modalIsOpen = true;
			this.modalType = this.type.DELETE;
		},

		async fetchParentReadNotification() {
			try {
				const {
					data: { data },
				} = await $api.notification.historySendFeeNotification(
					this.notification.tuition_notice_id
				);
				this.parents = data;
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		},

		async sendNotification() {
			this.inProgress = true;
			try {
				await $api.notification.sendFeeNotification(this.id);
				this.cancel();
				await this.fetchNotification();
				this.$store.dispatch('setAlertMessage', {
					message: 'Gửi thông báo thành công',
				});
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.inProgress = false;
			}
		},

		confirmSendOpen() {
			this.modalIsOpen = true;
			this.modalType = this.type.CREATE;
			this.notificationConfirm = {
				content: this.notification.notice?.content,
				title: this.notification.notice?.title,
				month: this.notification.month,
				student: this.notification.student,
				files: this.files,
				images: this.images,
			};
		},

		confirmResendOpen() {
			if (this.notification.lastest_notice_sent_at) {
				this.lastSendAt = timeSince(
					this.notification.lastest_notice_sent_at
				);
			}
			this.modalIsOpen = true;
			this.modalType = this.type.UPDATE;
		},

		async reSendNotification() {
			this.inProgress = true;
			try {
				await $api.notification.reSendFeeNotification(
					this.notification.tuition_notice_id
				);
				this.cancel();
				await this.fetchNotification();
				await this.fetchParentReadNotification();
				this.$store.dispatch('setAlertMessage', {
					message: 'Gửi thông báo thành công',
				});
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.inProgress = false;
			}
		},

		cancel() {
			this.modalIsOpen = false;
			this.modalType = '';
		},

		openMediaPopup(index) {
			this.imageShow = true;
			this.photosIndex = index;
		},
	},
};
</script>
<style lang="scss" scoped>
.title {
	font-weight: 600;
	box-shadow: none !important;

	&::placeholder {
		font-weight: normal;
	}
}
</style>
