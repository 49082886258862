<template>
	<div class="fixed inset-y-0 right-0 flex side-over z-50 w-screen md:w-2/5">
		<div
			class="
				h-full
				w-full
				flex flex-col
				bg-white
				shadow-xl
				overflow-y-auto
			"
		>
			<div class="px-6 py-8 bg-blue-50">
				<div class="flex items-start justify-between">
					<div class="flex items-center" @click="$emit('close')">
						<svg
							v-if="!data"
							fill="none"
							height="24"
							viewBox="0 0 24 24"
							width="24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								clip-rule="evenodd"
								d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM12.75 6.75C12.75 6.33579 12.4142 6 12 6C11.5858 6 11.25 6.33579 11.25 6.75V11.25H6.75C6.33579 11.25 6 11.5858 6 12C6 12.4142 6.33579 12.75 6.75 12.75H11.25V17.25C11.25 17.6642 11.5858 18 12 18C12.4142 18 12.75 17.6642 12.75 17.25V12.75H17.25C17.6642 12.75 18 12.4142 18 12C18 11.5858 17.6642 11.25 17.25 11.25H12.75V6.75Z"
								fill="#94A3B8"
								fill-rule="evenodd"
							/>
						</svg>
						<svg
							v-else
							fill="none"
							height="24"
							viewBox="0 0 24 24"
							width="24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								clip-rule="evenodd"
								d="M13.6308 5.68775L15.8838 3.43471C16.7496 2.56893 17.1825 2.13604 17.6895 1.99526C18.0396 1.89806 18.4095 1.89806 18.7596 1.99526C19.2667 2.13604 19.6996 2.56893 20.5653 3.43471C21.4311 4.3005 21.864 4.73339 22.0048 5.24044C22.102 5.59052 22.102 5.96046 22.0048 6.31054C21.864 6.8176 21.4311 7.25049 20.5653 8.11627L18.3384 10.3432C16.3859 9.2234 14.7651 7.61489 13.6308 5.68775ZM12.1764 7.14212L3.8659 15.4526C3.44084 15.8777 3.22831 16.0902 3.08857 16.3513C2.94884 16.6124 2.88989 16.9071 2.772 17.4966L1.98622 21.4255C1.91969 21.7581 1.88643 21.9244 1.98104 22.019C2.07564 22.1136 2.24194 22.0804 2.57452 22.0139L2.57455 22.0139L2.57456 22.0138L6.50349 21.2281L6.50351 21.2281C7.09295 21.1102 7.38767 21.0512 7.64876 20.9115C7.90986 20.7718 8.12239 20.5592 8.54743 20.1342L8.54745 20.1342L16.8803 11.8013C14.9786 10.6172 13.3722 9.02191 12.1764 7.14212Z"
								fill="#94A3B8"
								fill-rule="evenodd"
							/>
						</svg>

						<div
							class="
								text-2xl
								font-bold
								text-neutral-700
								ml-2
								mt-1
							"
						>
							<div v-if="!data">Thêm Chi phí</div>
							<div v-else-if="!student">Chỉnh sửa Chi phí</div>
							<div v-else>Chỉnh sửa Chi phí cho học sinh</div>
						</div>
					</div>
					<div class="ml-3 h-7 flex items-center">
						<button
							class="
								rounded-md
								text-neutral-400
								hover:text-neutral-700
							"
							type="button"
							@click="$emit('close')"
						>
							<span class="sr-only">Close panel</span>
							<svg
								aria-hidden="true"
								class="h-6 w-6"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M6 18L18 6M6 6l12 12"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div
				class="sideover-content mt-6 relative flex-1 px-4 sm:px-6 pb-20"
			>
				<div v-if="!data" class="mb-8 text-neutral-500">
					Bạn hãy điền các Chi phí cho các mô hình học dưới đây để tạo
					biểu mẫu học phí
				</div>
				<div v-if="student" class="mb-8 text-neutral-500">
					Bạn đang chỉnh sửa Chi phí học phí cho học sinh
					{{ student?.name }}
				</div>
				<div>
					<div class="input-group">
						<div class="input-group__label mb-2">
							Can thiệp cá nhân (/giờ)
						</div>
						<input
							v-if="student && !student?.has_personal_class"
							:disabled="true"
							class="bg-white"
							placeholder="Nhập chi phí (VNĐ)"
							value="Không học"
						/>
						<input
							v-else
							id="personal_price"
							:disabled="student && !student?.has_personal_class"
							:value="personal_price"
							class="bg-white"
							placeholder="Nhập chi phí (VNĐ)"
							@keyup="handlePriceInput('personal_price')"
						/>
					</div>
				</div>
				<div class="mt-8">
					<div class="input-group">
						<div class="input-group__label mb-2">
							Can thiệp nhóm (/giờ)
						</div>
						<input
							v-if="student && !student?.has_group_class"
							:disabled="true"
							class="bg-white"
							placeholder="Nhập chi phí (VNĐ)"
							value="Không học"
						/>
						<input
							v-else
							id="group_class_price"
							:disabled="student && !student?.has_group_class"
							:value="group_class_price"
							class="bg-white"
							placeholder="Nhập chi phí (VNĐ)"
							@keyup="handlePriceInput('group_class_price')"
						/>
					</div>
				</div>
				<div class="mt-8">
					<div class="input-group">
						<div class="input-group__label mb-2">
							Học bán trú (/ngày)
						</div>
						<input
							v-if="
								!org?.has_day_care ||
								(student && !student?.has_day_care_class)
							"
							:disabled="true"
							class="bg-white"
							placeholder="Nhập chi phí (VNĐ)"
							value="Không học"
						/>
						<input
							v-else
							id="day_care_price"
							:disabled="
								!org?.has_day_care ||
								(student && !student?.has_day_care_class)
							"
							:value="day_care_price"
							class="bg-white"
							placeholder="Nhập chi phí (VNĐ)"
							@keyup="handlePriceInput('day_care_price')"
						/>
					</div>
				</div>
				<div class="mt-8">
					<div class="input-group">
						<div class="input-group__label mb-2">
							Phí sử dụng sổ nhật ký điện tử (/tháng)
						</div>
						<input
							id="app_price"
							:value="app_price"
							class="bg-white"
							placeholder="Nhập chi phí (VNĐ)"
							@keyup="handlePriceInput('app_price')"
						/>
					</div>
				</div>
			</div>
			<div
				class="
					px-6
					py-4
					border-t border-yellow-50
					flex
					justify-end
					gap-6
				"
			>
				<div class="flex justify-end">
					<button
						:disabled="
							!app_price &&
							!day_care_price &&
							!group_class_price &&
							!personal_price
						"
						class="button-p text-center w-32"
						type="button"
						@click="submit"
					>
						Xác nhận
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'Setting',

	props: {
		open: {
			type: Boolean,
			required: true,
		},

		data: {
			type: Object,
			required: false,
		},

		student: {
			type: Object,
			required: false,
		},
	},

	watch: {
		open(val) {
			if (val) {
				if (this.data) {
					this.app_price = this.data.app_price
						? this.$filters.priceFormat(this.data.app_price)
						: null;
					this.day_care_price = this.data.day_care_price
						? this.$filters.priceFormat(this.data.day_care_price)
						: null;
					this.group_class_price = this.data.group_class_price
						? this.$filters.priceFormat(this.data.group_class_price)
						: null;
					this.personal_price = this.data.personal_price
						? this.$filters.priceFormat(this.data.personal_price)
						: null;
				} else {
					this.app_price = null;
					this.day_care_price = null;
					this.group_class_price = null;
					this.personal_price = null;
				}
			}
		},
	},

	data() {
		return {
			app_price: null,
			day_care_price: null,
			group_class_price: null,
			personal_price: null,
		};
	},

	computed: {
		org() {
			return this.$store.state.profile.current_org;
		},
	},

	methods: {
		submit() {
			if (this.student) {
				this.$emit('editForStudent', {
					studentId: this.student?.id,
					id: this.student?.student_tuition_unit_price?.id,
					payload: {
						app_price: this.app_price
							? this.onlyNumbers(`${this.app_price}`)
							: null,
						day_care_price: this.day_care_price
							? this.onlyNumbers(`${this.day_care_price}`)
							: null,
						group_class_price: this.group_class_price
							? this.onlyNumbers(`${this.group_class_price}`)
							: null,
						personal_price: this.personal_price
							? this.onlyNumbers(`${this.personal_price}`)
							: null,
					},
				});
				return;
			}

			this.$emit(this.data ? 'edit' : 'create', {
				app_price: this.app_price
					? this.onlyNumbers(`${this.app_price}`)
					: null,
				day_care_price: this.day_care_price
					? this.onlyNumbers(`${this.day_care_price}`)
					: null,
				group_class_price: this.group_class_price
					? this.onlyNumbers(`${this.group_class_price}`)
					: null,
				personal_price: this.personal_price
					? this.onlyNumbers(`${this.personal_price}`)
					: null,
			});
		},

		onlyNumbers(text) {
			return text.replace(/\D/g, '');
		},

		handlePriceInput(id) {
			const element = document.getElementById(id);
			if (!id || !element) {
				return;
			}

			if (!element.value && element.value !== '0') {
				this[id] = null;
				return;
			}

			element.value = this.$filters.priceFormat(
				this.onlyNumbers(`${element.value}`)
			);

			this[id] = element.value;
		},
	},
});
</script>
