<template>
	<div>
		<div :class="sideOverShow ? 'side-over--active' : ''">
			<div
				v-if="sideOverShow"
				class="
					fixed
					h-screen
					w-screen
					top-0
					left-0
					bg-black
					opacity-20
					z-30
				"
				@click="close"
			></div>
			<Setting
				:data="dataUpdate"
				:open="sideOverShow"
				:student="student"
				@close="close"
				@create="submit"
				@edit="edit"
				@editForStudent="editForStudent"
			/>
		</div>
		<div v-if="data">
			<div class="bg-white p-6 rounded-2xl shadow-sm text-gray-500">
				<div class="w-full flex items-center justify-between">
					<div class="text-lg font-semibold text-neutral-700">
						Chi phí
					</div>
					<button
						class="
							button-p
							text-center
							bg-none
							shadow-none
							flex
							items-center
							justify-center
							btn--text
							w-fit
						"
						type="button"
						@click="openEdit"
					>
						<svg
							class="mr-2"
							fill="none"
							height="16"
							viewBox="0 0 16 16"
							width="16"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								clip-rule="evenodd"
								d="M12.4702 6.65087L13.7102 5.41084C13.8461 5.27498 13.914 5.20705 13.9667 5.14509C14.6009 4.39841 14.6009 3.30223 13.9667 2.55555C13.914 2.49359 13.8461 2.42566 13.7102 2.2898C13.5744 2.15394 13.5064 2.08601 13.4445 2.03338C12.6978 1.39915 11.6016 1.39915 10.855 2.03338C10.793 2.086 10.7251 2.15391 10.5893 2.2897L10.5892 2.28978L10.5892 2.2898L9.33099 3.54801C10.0747 4.84221 11.1583 5.91748 12.4702 6.65087ZM7.87633 5.00267L2.86273 10.0163C2.43767 10.4413 2.22514 10.6539 2.0854 10.915C1.94567 11.1761 1.88672 11.4708 1.76883 12.0602L1.37318 14.0385C1.30666 14.3711 1.27339 14.5374 1.368 14.632C1.46261 14.7266 1.62892 14.6934 1.96153 14.6269L3.9398 14.2312L3.93982 14.2312L3.93984 14.2312C4.52927 14.1133 4.82399 14.0544 5.08508 13.9146C5.34617 13.7749 5.55869 13.5624 5.98373 13.1373L5.98376 13.1373L5.98377 13.1373L11.0118 8.10931C9.75111 7.3111 8.68202 6.24922 7.87633 5.00267Z"
								fill="currentColor"
								fill-rule="evenodd"
							/>
						</svg>
						Chỉnh sửa
					</button>
				</div>
				<div class="mt-4 flex flex-nowrap rounded-lg border">
					<div v-if="data?.personal_price" class="a-item p-4 w-full">
						<div>Can thiệp cá nhân</div>
						<div class="text-4xl font-bold text-neutral-600 mt-2">
							{{ $filters.priceFormat(data?.personal_price) }}
							VNĐ / giờ
						</div>
					</div>
					<div
						v-if="data?.group_class_price"
						class="a-item p-4 w-full"
					>
						<div>Can thiệp nhóm</div>
						<div class="text-4xl font-bold text-neutral-600 mt-2">
							{{ $filters.priceFormat(data?.group_class_price) }}
							VNĐ / giờ
						</div>
					</div>
					<div v-if="data?.day_care_price" class="a-item p-4 w-full">
						<div>Học bán trú</div>
						<div class="text-4xl font-bold text-neutral-600 mt-2">
							{{ $filters.priceFormat(data?.day_care_price) }}
							VNĐ / ngày
						</div>
					</div>
					<div v-if="data?.app_price" class="a-item p-4 w-full">
						<div>Sổ nhật ký điện tử</div>
						<div class="text-4xl font-bold text-neutral-600 mt-2">
							{{ $filters.priceFormat(data?.app_price) }}
							VNĐ / tháng
						</div>
					</div>
				</div>
				<div class="grid grid-cols-2 mt-6 gap-4">
					<div>
						<div class="font-semibold text-neutral-400">
							Số học sinh học can thiệp cá nhân
						</div>
						<div class="text-neutral-900 mt-2">
							{{ student_stats?.personanl_count ?? '-' }} học sinh
						</div>
					</div>
					<div>
						<div class="font-semibold text-neutral-400">
							Số học sinh học bán trú
						</div>
						<div class="text-neutral-900 mt-2">
							{{ student_stats?.day_care_count ?? '-' }} học sinh
						</div>
					</div>
					<div>
						<div class="font-semibold text-neutral-400">
							Số học sinh học can thiệp nhóm
						</div>
						<div class="text-neutral-900 mt-2">
							{{ student_stats?.group_class_count ?? '-' }} học
							sinh
						</div>
					</div>
					<div>
						<div class="font-semibold text-neutral-400">
							Số học sinh có học phí riêng
						</div>
						<div class="text-neutral-900 mt-2">
							{{ student_stats?.custom_price_count ?? '-' }} học
							sinh
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="students.length"
				class="shadow-sm sm:rounded-2xl mt-12 overflow-y-hidden"
			>
				<div class="sm:rounded-t-2xl w-fit min-w-full">
					<table class="min-w-full divide-y text-neutral-200">
						<thead class="bg-neutral-100 h-16">
							<tr>
								<th
									class="
										px-6
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										w-16
										whitespace-nowrap
									"
									scope="col"
								>
									STT
								</th>
								<th
									class="
										px-6
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
										w-32
									"
									scope="col"
								>
									Tên HIỂN THỊ
								</th>
								<th
									class="
										px-6
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
									"
									scope="col"
								>
									Can thiệp cá nhân
								</th>
								<th
									class="
										px-6
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
									"
									scope="col"
								>
									Can thiệp nhóm
								</th>
								<th
									class="
										px-6
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
									"
									scope="col"
								>
									Học bán trú
								</th>
								<th
									class="
										px-6
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
									"
									scope="col"
								>
									Sổ nhật ký điện tử
								</th>
								<th class="px-6 py-3" scope="col"></th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(student, index) in students"
								:key="student.id"
								:class="
									index % 2 === 0
										? 'bg-white'
										: 'bg-neutral-50'
								"
								class="
									text-neutral-700
									hover:bg-orange-50
									cursor-pointer
								"
							>
								<td>
									<div class="px-6 py-4 text-neutral-700">
										{{ index + 1 }}
									</div>
								</td>
								<td
									class="px-6 py-4 text-neutral-700"
									style="width: 250px"
								>
									<div class="flex items-center">
										<div class="relative">
											<img
												v-if="
													student?.profile_photo?.w200
												"
												:src="
													student?.profile_photo?.w200
												"
												alt=""
												class="
													shadow-sm
													inline-block
													h-6
													w-6
													rounded-full
												"
											/>
											<span
												v-else
												:style="{
													background:
														student?.profile_photo
															?.default?.color,
												}"
												class="
													inline-flex
													items-center
													justify-center
													h-6
													w-6
													rounded-full
												"
											>
												<span
													class="
														font-medium
														leading-none
														text-white text-xs
													"
													>{{
														student?.profile_photo
															?.default?.initial
													}}</span
												>
											</span>
											<div
												v-if="
													student?.profile_photo
														?.state !== 'active'
												"
												class="
													absolute
													transform
													-translate-x-1/2
													-translate-y-1/2
													top-1/2
													left-1/2
													h-6
													w-6
													rounded-full
													flex
													items-center
													justify-center
												"
												style="
													background-color: rgba(
														241,
														162,
														103,
														0.5
													);
												"
											>
												<svg
													fill="none"
													height="14"
													viewBox="0 0 40 40"
													width="14"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														clip-rule="evenodd"
														d="M9.84364 16.0997V10.2496C9.84364 4.86466 14.3907 0.5 20 0.5C25.6093 0.5 30.1564 4.865 30.1564 10.2496V16.0997C33.5218 16.0997 36.25 18.7188 36.25 21.9497V33.6499C36.25 36.8812 33.5218 39.5 30.1564 39.5H9.84364C6.47821 39.5 3.75 36.8812 3.75 33.6499V21.9497C3.75 18.7188 6.47821 16.0997 9.84364 16.0997V16.0997ZM26.0936 16.0997V10.2496C26.0936 7.01866 23.3654 4.3995 20 4.3995C16.6346 4.3995 13.9064 7.01866 13.9064 10.2496V16.0997H26.0936ZM20 23.9C21.1218 23.9 22.0311 24.7729 22.0311 25.8499V29.7501C22.0311 30.8271 21.1218 31.7 20 31.7C18.8782 31.7 17.9689 30.8271 17.9689 29.7501V25.8499C17.9689 24.7729 18.8782 23.9 20 23.9Z"
														fill="#FAF5F1"
														fill-rule="evenodd"
													/>
												</svg>
											</div>
										</div>
										<div
											class="
												ml-4
												text-orange-600
												font-semibold
												hover:underline
											"
											style="max-width: 150px"
											@click="
												$router.push(
													isAdmin &&
														!['pending'].includes(
															student.state
														)
														? `/record/student/${student.id}`
														: `/record/student/${student.id}/info`
												)
											"
										>
											{{ student.name }}
										</div>
									</div>
								</td>
								<td
									class="
										px-6
										py-4
										whitespace-nowrap
										text-neutral-700
									"
								>
									<div
										v-if="student?.has_personal_class"
										:class="{
											'text-red-500':
												student
													?.student_tuition_unit_price
													?.personal_price !==
												data?.personal_price,
										}"
									>
										{{
											$filters.priceFormat(
												student
													.student_tuition_unit_price
													?.personal_price
											)
										}}
										VNĐ / giờ
									</div>
									<div v-else>Không học</div>
								</td>
								<td
									class="
										px-6
										py-4
										whitespace-nowrap
										text-neutral-700
									"
								>
									<div
										v-if="student.has_group_class"
										:class="{
											'text-red-500':
												student
													?.student_tuition_unit_price
													?.group_class_price !==
												data?.group_class_price,
										}"
									>
										{{
											$filters.priceFormat(
												student
													.student_tuition_unit_price
													?.group_class_price
											)
										}}
										VNĐ / giờ
									</div>
									<div v-else>Không học</div>
								</td>
								<td
									class="
										px-6
										py-4
										whitespace-nowrap
										text-neutral-700
									"
								>
									<div
										v-if="student.has_day_care_class"
										:class="{
											'text-red-500':
												student
													?.student_tuition_unit_price
													?.day_care_price !==
												data?.day_care_price,
										}"
									>
										{{
											$filters.priceFormat(
												student
													.student_tuition_unit_price
													?.day_care_price
											)
										}}
										VNĐ / ngày
									</div>
									<div v-else>Không học</div>
								</td>
								<td
									class="
										px-6
										py-4
										whitespace-nowrap
										text-neutral-700
									"
								>
									<div
										:class="{
											'text-red-500':
												student
													?.student_tuition_unit_price
													?.app_price !==
												data?.app_price,
										}"
									>
										{{
											$filters.priceFormat(
												student
													.student_tuition_unit_price
													?.app_price
											)
										}}
										VNĐ / tháng
									</div>
								</td>
								<td class="w-10">
									<svg
										fill="none"
										height="20"
										viewBox="0 0 20 20"
										width="20"
										xmlns="http://www.w3.org/2000/svg"
										@click.stop="
											openEditStudent(
												student,
												student.student_tuition_unit_price
											)
										"
									>
										<path
											clip-rule="evenodd"
											d="M15.4041 8.49721L17.1377 6.76363L17.1377 6.76362L17.1377 6.7636C17.6368 6.26451 17.8863 6.01496 18.0269 5.74979C18.3376 5.16388 18.3376 4.46209 18.0269 3.87617C17.8863 3.611 17.6368 3.36144 17.1377 2.86233C16.6386 2.36322 16.389 2.11367 16.1238 1.97308C15.5379 1.66246 14.8361 1.66246 14.2502 1.97308C13.985 2.11367 13.7355 2.36322 13.2364 2.86234L11.4807 4.61806C12.4198 6.22862 13.772 7.57045 15.4041 8.49721ZM10.0262 6.07253L3.36419 12.7345C2.93913 13.1596 2.7266 13.3721 2.58687 13.6332C2.44713 13.8943 2.38819 14.189 2.27029 14.7785L1.67957 17.7321C1.61305 18.0647 1.57979 18.231 1.6744 18.3256C1.76901 18.4202 1.93531 18.387 2.26792 18.3204L5.22152 17.7297C5.81098 17.6118 6.1057 17.5529 6.3668 17.4131C6.6279 17.2734 6.84043 17.0609 7.26549 16.6358L13.9459 9.95544C12.3647 8.9642 11.027 7.63564 10.0262 6.07253Z"
											fill="#E5A42B"
											fill-rule="evenodd"
										/>
									</svg>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div
			v-else
			class="w-full h-full flex items-center justify-center flex-col"
		>
			<img
				alt=""
				height="250"
				src="@/assets/images/illustrations/not_found.png"
				width="250"
			/>
			<div
				class="text-neutral-500 text-lg text-center"
				style="max-width: 700px"
			>
				Trung tâm chưa có thiết lập Chi phí cho giờ học can thiệp và giờ
				học bán trú
			</div>
			<button
				class="button-p btn--ghost w-fit px-4 mt-4"
				type="button"
				@click="openCreate"
			>
				Thêm Chi phí
			</button>
		</div>
	</div>
</template>

<script>
import Setting from '@/views/tuitionSetting/Setting.vue';
import { $api } from '@/services';

export default {
	components: { Setting },

	props: {
		createTuition: {
			type: Number,
			default: 0,
		},
	},

	data() {
		return {
			sideOverShow: false,
			isLoading: false,
			data: null,
			student_stats: null,
			students: [],
			dataUpdate: null,
			student: false,
		};
	},

	watch: {
		createTuition: {
			handler(value) {
				if (value > 0) {
					this.sideOverShow = true;
				}
			},
		},
	},

	created() {
		this.fetchData();
	},

	computed: {
		isAdmin() {
			return this.$store.getters['auth/isAdmin'];
		},
	},

	methods: {
		openEdit() {
			this.dataUpdate = this.data;
			this.sideOverShow = true;
			this.student = null;
		},

		openEditStudent(student, data) {
			this.dataUpdate = {
				app_price: data.app_price ?? this.data.app_price,
				day_care_price: data.day_care_price ?? this.data.day_care_price,
				group_class_price:
					data.group_class_price ?? this.data.group_class_price,
				personal_price: data.personal_price ?? this.data.personal_price,
				id: data.id,
			};
			this.sideOverShow = true;
			this.student = student;
		},

		openCreate() {
			this.dataUpdate = null;
			this.sideOverShow = true;
			this.student = null;
		},

		close() {
			this.sideOverShow = false;
			this.dataUpdate = null;
			this.student = null;
		},

		async fetchData() {
			this.isLoading = true;
			try {
				const {
					data: { data, student_stats, students },
				} = await $api.org.getTuitionFee();
				this.data = data;
				this.student_stats = student_stats;
				this.students = students;
				if (!data) {
					this.$router.push({
						query: {
							empty: true,
						},
					});
				} else {
					this.$router.push({
						query: {},
					});
				}
			} catch (error) {
				console.log(error);
			} finally {
				this.isLoading = false;
			}
		},

		async submit(data) {
			try {
				await $api.org.createTuitionFee(data);
				this.close();
				await this.fetchData();
			} catch (err) {
				const error =
					err?.response?.data?.errors?.join(<br />) ||
					err?.message ||
					'Đã có lỗi xảy ra. Vui lòng thử lại';
				await this.$store.dispatch('setAlertMessage', {
					message: error,
					type: 'danger',
				});
			}
		},

		async edit(data) {
			try {
				await $api.org.updateTuitionFee(data);
				this.close();
				await this.fetchData();
			} catch (err) {
				const error =
					err?.response?.data?.errors?.join(<br />) ||
					err?.message ||
					'Đã có lỗi xảy ra. Vui lòng thử lại';
				await this.$store.dispatch('setAlertMessage', {
					message: error,
					type: 'danger',
				});
			}
		},

		async editForStudent(data) {
			const { studentId, id, payload } = data;
			try {
				await $api.org.updateTuitionFeeForStudent(
					studentId,
					id,
					payload
				);
				this.close();
				await this.fetchData();
			} catch (err) {
				const error =
					err?.response?.data?.errors?.join(<br />) ||
					err?.message ||
					'Đã có lỗi xảy ra. Vui lòng thử lại';
				await this.$store.dispatch('setAlertMessage', {
					message: error,
					type: 'danger',
				});
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.a-item {
	&:not(:last-child) {
		border-right: 1px solid #e5e7eb;
	}
}
</style>
